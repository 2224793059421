<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row v-if="loaderSkeleton">
        <v-col
          cols="12"
          md="4"
          v-for="(item, index) in 6"
          :key="index"
        >
          <div>
            <v-sheet
              color="grey lighten-4"
              class="pa-0"
            >
              <v-skeleton-loader
                class="mx-auto"
                height="150px"
                type="article"
              ></v-skeleton-loader>
            </v-sheet>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <div>
            <v-row v-if="aVendors.length > 0">
              <v-col
                v-for="(item, index) in aVendors"
                :key="index"
                cols="12"
                lg="4"
                md="6"
                sm="12"
              >
                <div
                  @click="redirectVendorDetail(item.sVendorEnterpriseId)"
                  class="container-card cursor-pointer"
                >
                  <div class="display-flex align-items-center">
                    <div class="content-image">
                      <img
                        v-if="Object.keys(item.oVendorLogo.oImages).length > 0"
                        :src="item.oVendorLogo.oImages.sm"
                        alt="proplat-image"
                      >
                      <img
                        v-else
                        src="@/assets/images/placeholder-image.jpeg"
                        alt="proplat-image"
                        style="background-color: #F4F4F4;"
                      >
                    </div>
                    <div class="restant-width ml-2">
                      <p class="text-markey mon-medium">{{ item.sName }}</p>
                      <div class="display-flex align-items-center mb-2">
                        <v-rating
                          :value="item.dRating"
                          length="5"
                          color="#FFC92A"
                          background-color="#C6C6C8"
                          dense
                          small
                          readonly
                        ></v-rating>
                        <p
                          :style="item.dRating == 0 ? { color: '#C6C6C8' } : {}"
                          class="text-calif-num mon-bold ml-1 mb-n1"
                        >
                          {{ 
                            (item.dRating > 5)
                            ? 5
                            : item.dRating
                          }}
                        </p>
                        <!-- <v-spacer></v-spacer>
                        <p class="text-calif mon-medium">(15 Calif)</p> -->
                      </div>
                      <!-- <div class="display-flex align-items-center">
                        <p class="text-response mon-medium mr-2">{{ texts.vendor.textResponseTime }}</p>
                        <v-spacer style="border: 1px dashed #BABABA"></v-spacer>
                        <p class="text-response-num mon-medium ml-2">30 min</p>
                      </div> -->
                      <div class="display-flex align-items-center">
                        <p class="texts-orders mon-medium mr-2">{{ texts.vendor.textFinishedOrders }}</p>
                        <v-spacer style="border: 1px dashed #BABABA"></v-spacer>
                        <p class="texts-orders-num mon-medium ml-2">{{ item.dFinishedOrders }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col>
                <div
                  v-if="aVendors.length <= 3"
                  style="height: 250px"
                />
              </v-col>
              <v-col cols="12">
                <div class="display-flex align-items-center justify-content-center">
                  <div class="pagination-styles">
                    <v-pagination
                      v-model="iCurrentPage"
                      :length="lengthPage"
                      color="#FFC556"
                      next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"
                    ></v-pagination>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <div class="empty-content display-flex align-items-center justify-content-center">
                  <p class="text-empty mon-regular">{{ texts.vendor.textEmpty }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "VendorContentLayout",
  data() {
    return {
      //VARIABLES
      aVendors: [],
      lengthPage: 0,
      iCurrentPage: 1,
      loaderSkeleton: true,
      logoImage: "",
      calification: 0,
    };
  },
  beforeMount() {
    this.getVendors();
  },
  methods: {
    redirectVendorDetail: function (id) {
      this.$router.push(`/vendor/detail/${id}`);
    },
    getVendors: function () {
      this.loaderSkeleton = true;

      DB.get(`${URI}/api/v1/${this.selectLanguage}/vendors/enterprises`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          sSearch: this.sSearch,
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: 6,
        },
      })
        .then((response) => {
          this.loaderSkeleton = false;
          this.aVendors = response.data.results;
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;
        })
        .catch((error) => {
          this.loaderSkeleton = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    sSearch: function () {
      return this.$store.state.sSearch;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getVendors();
      }
    },
    sSearch: lodash.debounce(function (val) {
      this.iCurrentPage = 1;
      this.getVendors();
    }, 500),
    refresh: function () {
      this.getVendors();
    },
    iCurrentPage: function () {
      this.getVendors();
    },
  },
};
</script>

<style scoped>
.text-calif {
  text-align: right;
  font-size: 12px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calif-num {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.cursor-pointer:hover {
  cursor: pointer;
  opacity: 0.5;
}

.text-markey {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-response,
.text-response-num,
.texts-orders,
.texts-orders-num {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #bababa;
  opacity: 1;
  margin-bottom: 5px;
}

.width-100 {
  width: 100%;
}

.container-card {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #151d2729;
  border-radius: 11px;
  opacity: 1;
  padding: 10px;
}

.restant-width {
  width: calc(100% - 100px);
}

.content-image {
  width: 100px;
  height: 100px;
  opacity: 1;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border: 1px solid #e8e8e8;
    border-radius: 19px;
  }
}

.text-empty {
  text-align: center;
  font-size: 20px;
  color: #c1c1c3;
}

.empty-content {
  width: 100%;
  height: 350px;
}
</style>